
@import url('https://fonts.googleapis.com/css2?family=Poppins');

body {
    font-family: open sans,sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #747e88;
    overflow-x: hidden;
}
.navbar-light .navbar-brand{
    font-size: 16px;
    font-weight: 600;
    color: #38424d;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    position: relative;
}
.navbar-light .navbar-nav .nav-link {
    font-size: 16px;
    font-weight: 600;
    color: #38424d;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    position: relative;
    padding-left:30px;
    padding-right:30px;
}
.contact-text{
    margin-top: 6px !important;
}
.nav-link:hover,.nav-link.active {
    font-weight: bold !important;
    color: #FFF !important;
    background-color: #3292F6;
}
.footer{
    background-color: #232323;
    color: #FFF;
}
.contact-us-btn{
    border: 2px solid #3292F6;
    border-radius: 30px;
    color: #3292F6 !important;
    justify-content: center;
    font-size: 1rem;
	font-weight: 400;
	padding: 12px 12px 12px 12px;
	margin-right: 20px;
	margin-left: 20px;
}
.justify-conten-center{
    margin-right:auto !important;
    margin-left:auto !important;
}
.contact-us-btn:hover{
    color: #3292F6 !important;
    background-color: #FFF !important;
    text-decoration: none !important;
}
.post-card{
    border-radius:8px;
}
.start-now-btn{
    background-color: #3292F6 !important;
    color: #FFF !important;
    border-radius: 30px;
    justify-content: center;
    font-size: 1rem;
	font-weight: 400;
	padding: 12px 30px 12px 30px;
    text-decoration: none;
}

.start-now-btn:hover{
    color: #3292F6 !important;
    border: 2px solid #3292F6;
    text-decoration: none !important;
}
.main-section{
    min-height: 80vh;
    margin-top: 80px;
    margin-bottom: 20px;
}
.intro-heading{
    margin-bottom: 15px;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #38424d;
    margin-top: 60px;
}
.intro-heading span{
   color: #3292F6;
}
.intro_b{
    font-size: 35px;
    color: #38424d;
}
.intro-img{
    height: auto;
    float: right;
}
.pos-desc{
    margin-top: 90px;
}
.pos-img{
    height: 350px;
    float: right;
}
.dash-img{
    height: 350px;
    float: left;
}
.logo{
    height: 50px;
}
.icon{
    height: 45px;
    width: 45px;
    object-fit: contain;
}
.intro-section{
  margin-top: 100px;
}
.intro_p {
    max-width: 570px;
    /* color: #fff; */
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 10px;
}
.contacts-card{
    padding: 15px;
    margin-top: -20px;
}
.join-section{
    background-color: #EEEEEE;
    padding-top: 20px;
    padding-bottom: 20px;
}
.module_title{
    font-size: 22px;
    line-height: 1.2em;
    font-weight: bold;
    color: #38424D;
    margin-top: 20px;
}
.black{
    color: #38424d;
}
.green{
    color: #3292F6;
}
.white{
    color: #FFF;
}
.bold{
    font-weight: bold;
}
.italic{
    font-style: italic;
}
.margin-top{
    margin-top: 10px;
}
.negative-margin-top{
    margin-top: -10px;
}
.tick{
    height: 20px;
    width: 20px;
    object-fit: contain;
}
.app-title{
    font-size: 45px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.app-desc{
    font-size: 31px;
}
.appstore{
    height: 72px;
    margin-top: 20px;
}
.playstore{
    height: 72px;
    margin-top: 20px;
}
.apps-img{
    transform: rotate(330deg);
}

/* Custom Scrollbar Start */

/* width */
::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #CCC; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #3292F6; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3292F6; 
  }

/* Custom Scrollbar End */


/*===================*/
/* BOUNCE OUT
/*===================*/

.bounce-out-on-hover {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  .bounce-out-on-hover:hover, .bounce-out-on-hover:focus, .bounce-out-on-hover:active {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  }

  .dash-section{
      background-color: #FFF;
      padding-top: 20px;
      padding-bottom: 20px;
  }
  .app-section{
    margin-top: -5px;
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .app-desc-section{
      margin-top: 100px;
  }
  .logo-app{
      margin-bottom: 50px;
  }
  .hide-mobi{
        display: block !important;
   }

   .show-mobi{
        display: none !important;
   }
   .margin-bottom-lg{
       margin-bottom: 30px;
   }
   .footer-ul{
    margin-left: -30px;
   }

.footer-icon{
    height: 15px;
    width: 15px;
    object-fit: contain;
}
.intro-img-section{
    text-align: center;
}
.list-unstyled a{
    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
}
.list-unstyled a:hover{
    color: #3292F6;
    transition: all 0.3s;
}
.footer-copyright{
    background-color: #343434;
    padding: 20px;
}
.top-title{
    margin-top: 100px !important;
}
.about_quote{
    font-style: italic;
    letter-spacing: .1em;
}
.about-content{
    /* margin: 50px; */
}
.padding{
    padding: 10px;
}
.accordion-item .title{
    font-weight: 700;
}
.accordion .accordion-item .title:after {
    content: '\2795';
    font-size: 13px;
    color: #3292F6 !important;
    float: right;
    margin-left: 5px;
}
.page-heading {
    text-align: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 230px 0px 150px 0px;
    color: #fff;
    position: relative;
}
.nav-img{
 height: 30px;
 width: 30px;
 object-fit: contain;
}
.nav-brand-title{
    font-weight: bold !important;
    font-size: 25px !important;
}
.contact-menu{
    height: 100vh;
    overflow-y: scroll;
    margin-top: 15px;
}
.avatar-md {
    width: 100%;
    height: 60px;
    min-width: 60px;
    max-width: 60px;
    border: 2px solid #CCC;
    border-radius: 100%;
    background-color:#CCC;
}
.avatar-comment{
    width: 100%;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    border: 2px solid #CCC;
    border-radius: 100%;
    background-color:#CCC;
}
.search{
    border-radius: 50px;
    background-color: #F3F3F5;
    margin-top: 30px;
}

.contact-name{
    margin-top: 10px;
    font-weight: 600;
    font-size: .9375rem;
    color: #484848;
}
.contact-p{
    color: #484848;
}
.contact-link{
    text-decoration: none!important;
}
.contact-link:hover{
    background-color: #F3F3F5;
}
.contact{
    margin-top: 10px;
    padding-top: 5px;
}
.contact:hover{
    background-color: #F3F3F5;
    border-radius: 10px;
}
.chat_h1 {
    font-size: 20px;
    font-weight: 800;
    color: #212529;
    padding-top: 18px;
    margin-bottom: 0;
}
.chat-list{
    max-height: 80vh;
    overflow-y: scroll;
}
.chat-bubble-in{
    background-color: #E2E4E9;
    display: inline-block;
    padding-bottom: 8px;
    word-break: break-word;
    max-width: 40%;
    color: #000;
    border-bottom-left-radius: 18px;
    border-top-right-radius: 18px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top:15px;
}
.chat-bubble-out{
    background-color: #0084FF;
    display: inline-block;
    padding: 15px;
    max-width: 40%;
    color: #FFF;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top:15px;
    float: right;
}
.main .chat .content .message.me {
    justify-content: flex-end;
}
.main .chat .content .message {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-bottom: 20px;
}
.main .chat .content .message .text-group.me {
    display: flex;
    justify-content: flex-end;
}
.main .chat .content .message .text-group {
    width: 100%;
    margin-bottom: 10px;
}
.main .chat .content .message .text.me {
    background: #2196F3;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-top-left-radius: 18px;
    border-bottom-right-radius: 18px;
    color: #FFF;
}
.main .chat .content .message .text {
    display: inline-block;
    padding: 15px;
    max-width: 450px;
    color: #000;
}
.main .chat .content .message .text {
    display: inline-block;
    max-width: 450px;
    background: #f5f5f5;
    border-radius: 6px;
}
.right{
    float: right;
}
.one-line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
 }
 .no-chat{
     margin-top:35vh;
 }
 .no-chat-heading{
   font-size:30px;
   color:#086AB6;
   align-self: center;
}
.page-heading h1 {
    text-transform: capitalize;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 18px;
}
.padding-md{
    padding-left:30px !important;
    padding-right:30px !important;
}
.login-card{
    margin: 100px auto;
    margin-top: 20vh;
}
.login-card-header{
    background-color: #0E5FA4 !important;
    color: #FFF;
}
.login-input{
    background-color: #F3F3F5;
    margin-top: 10px;
    margin-bottom: 10px;
}
.login-label-first{
    margin-top: 20px;
    color: #039BE5;
    font-weight: bold;
}
.login-label{
    margin-top: 5px;
    margin-bottom: 10px;
    color: #039BE5;
    font-weight: bold;
}
.error{
    color: red;
}
.reply-view {
    position: fixed;
    /* left: 0; */
    bottom: 0;
    width: 50%;
    /* background-color: #F5F5F5; */
    margin-bottom: 75px;
    color: black;
    text-align: center;
    align-self: center;
 }
 .btn-send{
     margin-right: -50px;
 }

.page-heading span {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    display: block;
}
.contact-item {
    padding: 60px 30px;
    background-color: #f7f7f7;
    text-align: center;
    object-fit: contain;
    min-height: 350px;
}
.contacts-section{
    margin-top: 30px;
}
.contact-icon {
    color: #3292F6;
    font-size: 48px;
    margin-bottom: 40px;
    height: 50px;
    width: 50px;
    object-fit: contain;
}
.contact-item h4 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.25px;
    margin-bottom: 15px;
}
.contact-item p {
    margin-bottom: 20px;
}
.contact-item a {
    font-weight: 600;
    color: #3292F6;
    font-size: 15px;
    text-decoration: none;
}

.contact-item a:hover {
    text-decoration: underline;
}

.shopkeeper-img{
    height: 250px;
    border: 5px solid #3292F6;
    float: right;
}
.how-it-works-section{
    margin-right: 48px;
    margin-left: 48px;
}
.steps-cont{
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 10px;
    border-radius: 100px;
    background-color: #3292F6;
    color: #FFF;
}
.steps-section{
    margin-top: 20px;
    margin-bottom: 20px;
}
.black-line{
    margin-right: auto; 
    margin-left: auto; 
    position: relative; 
    border-top: 3px solid black; 
    width: 5%; 
    margin-bottom: 10px;
}





/*Lets make it responsive*/



@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .contact-us-btn{
        width: 157px !important;
    }
    .intro-heading{
        margin-top: 5px !important;
        font-size: 20px !important;
        text-align: center;
    }
    .start-btn-cont{
        text-align: center;
    }

    .hide-mobi{
        display: none !important;
    }

    .show-mobi{
        display: block !important;
    }

    .pos-desc{
        margin-top: 10px !important;
    }
    .pos-img-cont{
        text-align: center;
    }
    .pos-img {
        height: 260px;
        width: auto;
        object-fit: contain;
    }
    .intro-img{
        height: 260px;
        object-fit: contain;
        float: none;
    }
    .pos-intro{
        text-align: center;
    }
    .dash-img{
        height: 260px;
        object-fit: contain;
    }
    .apps-img-section{
        text-align: center;
        margin-top: 50px;
    }
    .appstore{
        height: 50px;
        margin-top: 20px;
    }
    .playstore{
        height: 50px;
        margin-top: 20px;
    }
    .apps-img{
        height: 400px;
        width: auto;
        object-fit: contain;
    }
    .app-title{
        font-size: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .app-desc{
        font-size: 20px;
    }
    .app-desc-section{
        margin-top: 10px;
    }
    .logo-app{
        margin-bottom: 10px !important;
    }
    .title{
        text-align: center;
    }
    .p-footer{
        text-align: center;
    }
    .footer-ul{
        text-align: center;
    }
    
}
